import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
} from '@coreui/icons'
import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
} from '@coreui/icons'
import {
    cilArrowRight,
    cilBan,
    cilBarChart,
    cilBasket,
    cilBell,
    cilBank,
    cilBullhorn,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilClipboard,
    // cilCircleCheck,
    cilChatBubble,
    cilCommentSquare,
    cilCommentBubble,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilWindowMaximize,
    cilImage,
    cilNewspaper,
    cilHome,
    cilCart,
    cilCash,
    cilGift

} from '@coreui/icons'

export const iconsSet = Object.assign({}, {
    cilArrowRight,
    cilBan,
    cilBarChart,
    cilBasket,
    cilBell,
    cilBank,
    cilBullhorn,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilClipboard,
    // cilCircleCheck,
    cilChatBubble,
    cilCommentSquare,
    cilCommentBubble,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilWindowMaximize,
    cilImage,
    cilNewspaper,
    cilHome,
    cilCart,
    cilCash,
    cilGift
}, {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
}, {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
})